.categories {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 2rem;
  }
  
  .title1 {
    font-weight: 700;
    font-size: 42px;
    color: #333;
    margin-bottom: 3rem;
  }
  
  .category-button {
    position: relative;
    width: 250px;
    padding: 1rem;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    margin-bottom: 2rem;
  }
  
  .category-button:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .category-button.active {
    transform: scale(1.05);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .category-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
  }
  
  .category-title {
    margin-top: 1rem;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
  }
  
  .products-title {
    font-size: 36px;
    color: #333;
    font-weight: bold;
    margin-top: 3rem;
    margin-bottom: 1rem;
  }
  
  .product-list {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  
  @media screen and (min-width: 600px) and (max-width: 1023px) {
    .product-list {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
  
  @media screen and (min-width: 1024px) {
    .product-list {
      margin-left: 2rem;
      margin-right: 2rem;
    }
  }
  