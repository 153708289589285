.image-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 890px;
    max-width: 100vw;
    overflow: hidden;
  }
  
  .image-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    height: 890px;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as desired */
  }
  
  .image-container img {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .image-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #ffffff;
  }
  
  .main-heading {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 62px;
    margin-bottom: 10px;
  }

  .main-heading1 {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 42px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .sub-heading {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 35px;
    padding-bottom: 5rem;
    margin-bottom: 20px;
    color: #ffffff;
  }

  .paragraph1{
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 20px;
    margin-left: 3rem;
    margin-right: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 0;
  }

  .paragraph2{
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 20px;
    margin-left: 3rem;
    margin-right: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 1rem;
  }

  .bullet{
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 1rem;
  }
  
  .get-started-button {
    padding: 7px 20px 10px;
    border-radius: 25px;
    background-color: #2cb454;
    font-family: 'Lato', sans-serif;
    color: #ffffff;
    font-weight: bold;
    text-decoration: none;
    transition: background-color 0.3s ease, color 0.3s ease;
    border: 2.3px solid #2cb454;
  }
  
  .get-started-button:hover {
    background-color: transparent;
    color: #ffffff;
  }
  
  /*.about-text-container2 {
    background-color: #f4f4f4;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 2rem;
    margin-top: 4rem;
    max-width: 100%;
    padding-top: 5rem;
    padding-bottom: 5rem;
  }*/
  
  .about-text-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  /* Footer column */
  .about-text-column1 {
    padding-top: 0rem;
    font-size: 28px;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-left: 10rem;
    margin-left: 5rem;
    padding-right: 3rem;
    align-items: center;
    text-align: center;
    width: 50%;
  }
  
  .about-text-column2 {
    padding-top: 0rem;
    font-size: 28px;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-left: 3rem;
    margin-right: 5rem;
    padding-right: 10rem;
    align-items: center;
    text-align: center;
    width: 50%;
  }
  
  .about-column-title {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 20px;
    color: #333;
  }
  
  .about-column-paragraph {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 24px;
    color: #333;
  }

  @media screen and (max-width: 1024px) {
    .image-container {
      height: 600px;
    }

    .main-heading {
      font-size: 40px;
    }

    .main-heading1{
      font-size: 30px;
    }
  
    .sub-heading {
      font-size: 28px;
    }

    .paragraph1{
      font-size: 18px;
    }

    .paragraph2{
      font-size: 18px;
    }

    .bullet{
      font-size: 18px;
    }

    .about-text-column1 {
      padding-left: 1.5rem;
      padding-right: 1rem;
    }

    .about-text-column2{
      padding-left: 1rem;
      padding-right: 1.5rem;
    }
  }
  
  @media screen and (max-width: 600px) {
    .image-container{
      height: 300px;
    }
    
    .main-heading {
        width: 100%;
        font-size: 30px;
        margin: auto;
        padding-left: 0;
        padding-right: 0;
    }

    .main-heading1 {
      font-size: 25px;
    }

    .about-column-title{
      font-size: 20px;
    }
  
    .sub-heading {
      font-size: 20px;
      margin: 1rem auto;
    }

    .paragraph1 {
      font-size: 16px;
      margin-left: 1rem;
      margin-right: 1rem;
    }

    .bullet{
      font-size: 16px;
    }


    .paragraph2 {
      font-size: 16px;
      margin-left: 1rem;
      margin-right: 1rem;
    }

    .about-text-row {
      flex-direction: column;
      align-items: center;
    }
  
    .about-text-column1,
    .about-text-column2 {
      align-items: center;
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      margin-left: 0;
      margin-right: 0;
    }
  
  }
  