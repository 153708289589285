:root {
  --mainColor: #ffffff;
  --mainColorLight: #5767aa;
  --secondaryColor: #2cb454;
  --textColor: #1f1f1f;
}


header {
  position: sticky;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 65px;
  padding: 0 2rem;
  background-color: var(--mainColor);
  color: var(--textColor);
  font-family: 'Lato', sans-serif;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

header.scrolled {
  position: sticky;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 58px;
  padding: 0 2rem;
  background-color: var(--mainColor);
  color: var(--textColor);
  font-family: 'Lato', sans-serif;
  font-weight: bold;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
}

.logo img {
  height: 40px; /* Adjust the height as needed */
}

nav {
  display: flex;
  align-items: center;
  margin-left: auto;
}

nav a {
  margin-left: 1.5rem;
  color: var(--textColor);
  text-decoration: none;
}

nav a:hover {
  color: var(--secondaryColor);
}

nav .rounded-button {
  padding: 7px 20px;
  border-radius: 25px;
  background-color: transparent;
  color: var(--secondaryColor);
  font-weight: bold;
  text-decoration: none;
  transition: background-color 0.3s ease, color 0.3s ease;
  border: 2.3px solid var(--secondaryColor);
}

nav .rounded-button:hover {
  background-color: var(--secondaryColor);
  color: var(--mainColor);
}

header .nav-btn {
  padding: 5px;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: var(--textColor);
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}

header div,
nav {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1024px) {
  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }

  header nav {
    position: fixed;
    top: -100vh;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Add this line for center alignment */
    gap: 1.5rem;
    background-color: var(--mainColor);
    transition: 1s;
  }

  header .responsive_nav {
    transform: translateY(100vh);
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  nav a {
    font-size: 1.5rem;
  }
}
