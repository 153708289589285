.thin-footer {
    background-color: #333;
    padding: 20px 0;
    text-align: center;
    border-radius: 0 0 10px 10px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .container p {
    margin: 0;
  }