.product-card {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    margin: 10px;
    width: calc(50% - 20px); /* 2 products per row for mobile */
    display: inline-block;
    box-sizing: border-box;
    text-align: center;
  }
  
  /* Tablet styles */
  @media screen and (min-width: 768px) {
    .product-card {
      width: calc(33.33% - 20px); /* 3 products per row for tablet */
    }
  }
  
  /* Desktop styles */
  @media screen and (min-width: 1024px) {
    .product-card {
      width: calc(16.66% - 20px); /* 6 products per row for desktop */
    }
  }
  
  .product-image {
    width: 100%;
    height: 200px; /* Adjust the desired height */
    object-fit: cover;
    border-radius: 4px;
  }
  
  
  .product-details {
    margin-top: 10px;
  }
  
  .product-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .product-description {
    font-size: 14px;
  }
  